import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# METHODS
import CancelDeliveryService from '../methods/deliveries/cancel_delivery_service'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_delivery_service'
  return false unless el?
  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      product: []
      orde_delivery: []
    }
    methods: {
      CancelDeliveryService
    }
    mounted: ->
      id = document.getElementById('id').dataset.id
      uid = document.getElementById('uid').dataset.uid
      query = $.get "/customers/#{uid}/delivery_services/#{id}.json"
      query.done (res) =>
        @product = res.products
        @orde_delivery = res.orde_delivery
    computed: {
      subtotal: ->
        {product} = @
        acum = 0
        if product.subtotal > 0
          acum += parseInt product.subtotal
        acum
      discount: ->
        {product} = @
        acum = 0
        if product.dicount > 0
          acum += parseInt product.dicount
        acum
      iva: ->
        {product} = @
        acum = 0
        if product.paid_tax > 0
          acum += parseInt product.paid_tax
        acum
      total: ->
        {product} = @
        acum = 0
        if product.paid_price > 0
          acum += parseInt product.paid_price
        acum
      delivery: ->
        {product} = @
        acum = 0
        if product.shipping > 0
          acum += parseInt product.shipping
        acum
      total_with_delivery: ->
        {product} = @
        acum = 0
        if product.paid_price > 0
          acum += parseInt product.paid_price + product.shipping
        acum
    }
  }
