import AxiosRequest from "../../utils/axios-request"

export default (uid) ->
  AxiosRequest("/customers/#{uid}/delivery_services/cancel", {id: @orde_delivery.idscheduled_service}, 'post').then ({data}) ->
    Swal.fire({
      icon: 'success'
      title: data.message
      showConfirmButton: false
      timer: 2000
      timerProgressBar: true
    }).then ->
      location.reload()
